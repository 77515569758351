<template>
  <div class="home">
    <div class="main">
      <b-container fluid>
        <b-row class="no-padding text-center mb-4" no-gutters>
          <b-col>
            <div class="banner" ref="banner">
              <b-icon-gear
                v-b-modal.settingModal
                class="border rounded btn-upload-img p-1"
              ></b-icon-gear>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="header my-3">
          <b-col class="text-center"
            ><img
              :src="require('@/assets/head-logo.png')"
              alt=""
            />專用抽獎系統</b-col
          >
        </b-row>

        <b-card class="mb-2">
          <b-row class="lottoInfo">
            <b-col cols="8">
              <div class="label">活動名稱</div>
              <div class="text">{{ info.name }}</div>
            </b-col>
            <b-col cols="4">
              <div class="label">日 期</div>
              <div class="text">{{ info.date }}</div>
            </b-col>
          </b-row>
          <b-row class="lottoInfo mt-4" v-if="info.note">
            <b-col cols="12">
              <div class="label align-top">注意事項</div>
              <div class="text textarea">{{ info.note }}</div>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mb-2">
          <b-row>
            <b-col cols="3">
              <b-button
                class="px-4"
                variant="danger"
                @click="
                  $refs.listFile.$el.querySelector('input[type=file]').click()
                "
                >上傳名單檔案</b-button
              >
              <b-form-file
                v-model="listFile"
                ref="listFile"
                class="hidden"
              ></b-form-file>
            </b-col>
            <b-col
              class="listText d-flex justify-content-center align-items-center"
            >
              總留言人數：<span class="strong">{{ list.length }}</span> 人
              <span class="space"></span>
              完成抽獎條件：<span class="strong">{{ listQualify.length }}</span>
              人
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col cols="3">
              <b-button
                class="px-4"
                variant="danger"
                @click="
                  $refs.rewardFile.$el.querySelector('input[type=file]').click()
                "
                >上傳獎項檔案</b-button
              >
              <b-form-file
                v-model="rewardFile"
                ref="rewardFile"
                class="hidden"
              ></b-form-file>
            </b-col>
            <b-col>
              <b-table
                v-if="reward.length"
                bordered
                :items="reward"
                :fields="rewardFields"
              >
              </b-table>
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col class="text-center mt-3 mb-3">
            <b-button class="px-5" variant="danger" size="lg" @click="hit()"
              >開始抽獎</b-button
            >
          </b-col>
        </b-row>
        <b-row v-if="hitStatus">
          <b-col>
            <b-card class="text-center">
              <b-table
                class="mt-3"
                ref="lottoTable"
                v-if="reward.length"
                bordered
                :items="reward"
                :fields="lottoFields"
              >
                <template #cell(taking)="data">
                  <div v-for="(user, index) in data.item.taking">
                    <a :href="user.url" target="_blank">{{ user.name }}</a>
                  </div>
                </template>
                <template #cell(waiting)="data">
                  <div v-for="(user, index) in data.item.waiting">
                    <a :href="user.url" target="_blank">{{
                      `${index + 1}. ` + user.name
                    }}</a>
                  </div>
                </template>
              </b-table>
              <div class="text-center mt-3">
                <b-button
                  class="px-4"
                  variant="danger"
                  @click="downloadRewardList()"
                  >下載中獎名單</b-button
                >
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div
      class="footer mt-3"
      :style="{ backgroundColor: footerBgColor, color: footerTextColor }"
    >
      <b-container>
        <b-row>
          <b-col>
            <img :src="require('@/assets/footer-logo.png')" alt="" />
          </b-col>
          <b-col class="text-right"
            >701 臺南市東區大學路1號 ｜ 06-2757575</b-col
          >
        </b-row>
      </b-container>
    </div>
    <b-modal id="settingModal" title="設定" ok-only ok-variant="danger">
      <b-form-group label="Banner 圖片" label-for="bgImage">
        <b-form-file id="bgImage" v-model="bgImage"></b-form-file>
      </b-form-group>
      <b-form-group label="活動名稱" label-for="infoName">
        <b-form-input id="infoName" v-model="info.name"></b-form-input>
      </b-form-group>
      <b-form-group label="活動日期" label-for="infoDate">
        <b-form-input id="infoDate" v-model="info.date"></b-form-input>
      </b-form-group>
      <b-form-group label="注意事項" label-for="infoNote">
        <b-form-textarea
          id="infoNote"
          v-model="info.note"
          placeholder="請輸入注意事項..."
          rows="3"
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Home",
  data() {
    return {
      info: {
        date: "未設定",
        name: "未設定",
        note: "",
      },
      hitStatus: false,
      listFile: null,
      rewardFile: null,
      bgImage: null,
      footerBgColor: "#A71522",
      footerTextColor: "#ffffff",
      rewardFields: [
        {
          key: "reward",
          label: "獎項",
          class: "text-center",
        },
        {
          key: "number",
          label: "數量",
          class: "text-center",
        },
      ],
      lottoFields: [
        {
          key: "reward",
          label: "獎項",
          class: "text-center",
        },
        {
          key: "number",
          label: "數量",
          class: "text-center",
        },
        {
          key: "taking",
          label: "正取者",
        },
        {
          key: "waiting",
          label: "備取者",
        },
      ],
      list: [],
      shuffleList: [],
      reward: [],
    };
  },
  computed: {
    listQualify() {
      let users = _.map(this.list, (o, i) => {
        let eq = _.find(this.list, (e, ind) => {
          if (i > ind) {
            return _.isEqual(e.url, o.url);
          }
        });
        if (eq) {
          o.valid = "0";
          return o;
        } else {
          return o;
        }
      });

      users = users.map((user) => {
        user.weight = user.weight ? parseInt(user.weight) : 1;
        return user;
      });

      users = users.filter((user) => {
        return parseInt(user.valid);
      });

      return users;
    },
  },
  methods: {
    downloadRewardList() {
      let csvStr = "獎項,類別,中獎者,URL" + "\n";
      this.reward.forEach((reward) => {
        reward.taking.forEach((user) => {
          csvStr +=
            reward.reward +
            "," +
            "正取" +
            "," +
            user.name +
            "," +
            user.url +
            "\n";
        });
        reward.waiting.forEach((user) => {
          csvStr +=
            reward.reward +
            "," +
            "備取" +
            "," +
            user.name +
            "," +
            user.url +
            "\n";
        });
      });

      let hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
      hiddenElement.target = "_blank";
      hiddenElement.download = this.info.name + "得獎名單.csv";
      hiddenElement.click();
    },
    hit() {
      const randomShuffleFn = () => Math.random() - 0.5;
      const shuffleFn = (candidateA, candidateB) =>
        Math.random() * (candidateB.weight + candidateA.weight) -
        candidateA.weight;

      this.shuffleList = [...this.listQualify]
        .sort(randomShuffleFn)
        .sort(shuffleFn);

      this.hitStatus = true;

      let index = 0;
      this.reward.forEach((item) => {
        item.taking = [];
        for (let i = 0; i < item.number; i++) {
          item.taking.push(this.shuffleList[index]);
          index++;
        }
      });
      this.reward.forEach((item) => {
        item.waiting = [];
        for (let i = 0; i < item.number; i++) {
          item.waiting.push(this.shuffleList[index]);
          index++;
        }
      });
      this.$refs.lottoTable.refresh();
    },
    readCSV(from, to) {
      let reader = new FileReader();
      reader.onload = (event) => {
        let rows = event.target.result.split("\n");
        let header = rows.shift();
        let keys = header.split(",");

        this[to] = rows.map((row) => {
          let data = {};
          row = row.split(",");
          keys.forEach((key, index) => {
            key = key.trim();
            key = key.replace("\n", "'");
            key = key.replace("\r", "");
            data[key] = row[index].trim();
          });
          return data;
        });
      };
      reader.readAsText(this[from]);
    },
    deIdentification(str) {
      const showLen = Math.round(str.length / 2); // 顯示幾個
      const markLen = str.length - showLen; // 要隱藏幾個
      const showStart = Math.round((str.length - showLen) / 2); // 從哪開始隱
      return str.replace(str.substr(showStart, markLen), "*".repeat(markLen));
    },
  },
  watch: {
    listFile() {
      this.readCSV("listFile", "list");
    },
    rewardFile() {
      this.readCSV("rewardFile", "reward");
    },
    bgImage() {
      let reader = new FileReader();
      reader.onload = () => {
        this.$refs.banner.style.backgroundImage = "url(" + reader.result + ")";
      };
      reader.readAsDataURL(this.bgImage);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .main {
    flex: 1;
  }
}
.no-padding {
  margin-right: -15px;
  margin-left: -15px;
}
.hidden {
  display: none;
}
.card {
  background-color: #e6e6e6;
}
.table {
  background-color: #fff;
}
.listText {
  font-size: 24px;
  font-weight: 600;
  .strong {
    color: #a71522;
    text-decoration: underline;
  }
  .space {
    margin-right: 30px;
  }
}
.header {
  font-size: 32px;
  color: #a71522;
  font-weight: 600;
  line-height: 80px;
  img {
    height: 70px;
    margin-right: 10px;
    position: relative;
    top: -5px;
  }
}
.banner {
  height: 280px;
  width: 100%;
  background-color: #666;
  background-size: cover;
  .btn-upload-img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 26px;
    color: #fff;
    background-color: #00000059;
    cursor: pointer;
  }
}
.lottoInfo {
  font-size: 16px;
  line-height: 42px;
  .label {
    background-color: #626365;
    color: #fff;
    width: 120px;
    text-align: center;

    display: inline-block;
  }
  .text {
    white-space: pre;
    display: inline-block;
    background-color: #fff;
    width: calc(100% - 120px);
    padding: 0 20px;
    &.textarea {
      padding-top: 6px;
      padding-bottom: 6px;
      min-height: 42px;
      line-height: 30px;
    }
  }
}
.footer {
  min-height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  font-size: 14px;
  img {
    height: 42px;
  }
}
</style>
